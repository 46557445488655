@tailwind base;
@tailwind components;
@tailwind utilities;




/* Estilo customizado para o NProgress */
#nprogress .bar {
    background: linear-gradient(to right, #ff80b5, #9089fc);
    height: 4px;
  }
  
  #nprogress .peg {
    box-shadow: 0 0 10px #ff80b5, 0 0 5px #9089fc;
  }
  
  #nprogress .spinner-icon {
    border-top-color: #ff80b5;
    border-left-color: #9089fc;
  }
  